import { actions } from "../actions/payment.action";

const initialState = {
  isPaymentSuccessful: false
};

export default function payment(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_PAYMENT_INTENT_SUCCESS:
      return { ...state, isLoadingIntent: false };
    case actions.CREATE_PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        requestedId: action.requestedId,
        paymentError: action.error,
        isLoadingIntent: false
      };
    case actions.CREATE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        requestedId: action.requestedId,
        isLoadingIntent: true,
        paymentError: null
      };
    case actions.FETCH_COUPON_REQUEST:
      return {
        ...state,
        isLoadingCoupon: true,
        couponError: null
      };
    case actions.FETCH_COUPON_SUCCESS:
      return {
        ...state,
        isLoadingCoupon: false,
        currentPrice: action.payload
      };
    case actions.FETCH_COUPON_FAILURE:
      return {
        ...state,
        isLoadingCoupon: false,
        couponError: action.error,
        currentPrice: {}
      };

    case actions.MAKE_PAYMENT_REQUEST:
      return {
        ...state,
        isLoadingPayment: true,
        payment: null,
        paymentError: null
      };

    case actions.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoadingPayment: false,
        payment: action.payload,
        isPaymentSuccessful: true,
        paymentError: null
      };
    case actions.MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        isLoadingPayment: false,
        payment: null,
        paymentError: action.error
      };
    case actions.UPDATE_PAYMENT_ERROR_REQUEST:
      return { ...state, isLoadingPayment: true };
    case actions.UPDATE_PAYMENT_ERROR_SUCCESS:
      return { ...state, data: action.payload, isLoadingPayment: false };
    case actions.UPDATE_PAYMENT_ERROR_FAILURE:
      return { ...state, paymentError: action.error, isLoadingPayment: false };
    case actions.FETCH_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoadingAllProducts: true,
        error: null
      };
    case actions.FETCH_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoadingAllProducts: false,
        products: action.payload
      };
    case actions.FETCH_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoadingAllProducts: false,
        error: action.payload,
        products: []
      };
    case actions.FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        isPaymentSuccessful: false,
        isLoadingProduct: true,
        currentPrice: {},
        currentProduct: null,
        error: null
      };
    case actions.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoadingProduct: false,
        currentProduct: action.payload
      };
    case actions.FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        isLoadingProduct: false,
        error: action.payload,
        currentProduct: null
      };
    case actions.FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isLoadingCustomer: false,
        customer: action.payload,
        customerError: {}
      };
    case actions.FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        isLoadingCustomer: false,
        customer: null,
        customerError: action.error
      };
    case actions.FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoadingCustomer: true,
        customer: null,
        customerError: null
      };
    default:
      return state;
  }
}
