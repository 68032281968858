import React, { Component } from "react";
import PropTypes from "prop-types";

// scss
import "../../Achievements.scss";

class Achievement extends Component {
  render() {
    const { image, title } = this.props;

    return (
      <div className="achievement">
        <div className="wrapper">
          <img src={image} alt="achiv" />
          <span>{title}</span>
        </div>
      </div>
    );
  }
}

Achievement.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string
};

export default Achievement;
