/*eslint no-magic-numbers: 0*/
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { hasPermission } from "../../store/helpers/userHelper";

// scss
import "./TableFooter.scss";

class TableFooter extends Component {
  handlePageBefore() {
    let page = this.props.page;
    if (page !== 0) {
      page = page - 1;
    }
    this.props.changePage(page);
  }

  handlePageAfter() {
    const { count, page, rowsPerPage } = this.props;

    const pagesTotal = Math.ceil(count / rowsPerPage) - 1;
    let currentPage = page;

    if (currentPage !== pagesTotal) {
      currentPage = currentPage + 1;
      this.props.changePage(currentPage);
    }
  }

  showResults() {
    const { count, page, rowsPerPage } = this.props;
    const pagesTotal = Math.ceil(count / rowsPerPage) - 1;

    let result = rowsPerPage;

    if (page === pagesTotal) {
      result = count % rowsPerPage;
    }

    return result;
  }

  render() {
    const {
      isTeam360,
      count,
      user,
      handleTeamMemberDialogOpen,
      canSeeLastReport,
      isViewOnlyUser
    } = this.props;

    return (
      <Fragment>
        <tfoot>
          <tr className="table-footer">
            <td>
              <span>{`Showing ${count} Team Members`}</span>
              {hasPermission(user.role) && !isTeam360 && !isViewOnlyUser && canSeeLastReport &&  (
                <button
                  className="table-footer__reset"
                  onClick={() => {
                    handleTeamMemberDialogOpen(null, "reset");
                  }}
                >
                  <span>Start new questionnaire</span>
                </button>
              )}
            </td>
          </tr>
        </tfoot>
      </Fragment>
    );
  }
}

TableFooter.propTypes = {
  changePage: PropTypes.func,
  count: PropTypes.number,
  canSeeLastReport: PropTypes.bool,
  isTeam360: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  sendAll: PropTypes.func,
  user: PropTypes.object,
  handleTeamMemberDialogOpen: PropTypes.func,
  isViewOnlyUser: PropTypes.bool,
};

export default TableFooter;
