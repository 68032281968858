import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PageLoading from "../../../../components/PageLoading";
import Dropdown from "../../../../components/Dropdown";
import TextField from "../../../../components/TextField";

//actions
import {
  fetchCoupon,
  fetchProduct,
  fetchCustomer,
} from "../../../../store/actions/payment.action";
import { fetchCountries } from "../../../../store/actions/general.action";

//utils
import * as data from "./data";
import { getFormsValidityStatus } from "../../../../store/services";
import { notifyError } from "../../../../utils/notifications";
import { getCurrency } from "../../../../store/helpers/uiHelper";

// scss
import "./Product.scss";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerFields: [
        { data: data.firstNameData },
        { data: data.surnameData },
        { data: data.companyNameData },
        { data: data.addressData },
        { data: data.countryData },
        { data: data.townCityData },
        { data: data.postCodeData },
        { data: data.phoneNumberData },
        { data: data.emailData },
      ],
      isFormSubmitted: false,
      customer: this.getAccountInfo(),
      coupon: null,
      appliedCoupon: null,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    const { report } = this.props.match.params;
    this.props.fetchProduct(report);
    this.props.fetchCountries();
    this.props.fetchCustomer();
  }

  componentWillReceiveProps(nextProps) {
    const { account, customer, countries } = nextProps;
    if (customer && customer !== this.state.customer) {
      this.setState(() => ({
        customer,
      }));
    }

    if (
      !customer &&
      countries.length > 0 &&
      !this.state.customer.country_name
    ) {
      this.updateFormFieldValue("country_id", parseInt(account.country_id));
    }
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    if (fieldName === "country_id") {
      this.setState((prevState) => ({
        customer: {
          ...prevState.customer,
          country_name: this.getCountryName(parseInt(fieldValue)),
          country_id: fieldValue,
        },
      }));
    } else {
      this.setState((prevState) => ({
        customer: { ...prevState.customer, [fieldName]: fieldValue },
      }));
    }
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  getAccountInfo() {
    const { account, countries } = this.props;
    const customer = {
      first_name: account && account.first_name,
      surname: account && account.surname,
      town_city: account && account.town_city,
      post_code: account && account.post_code,
      email: account && account.email,
      country_name:
        account &&
        countries &&
        this.getCountryName(parseInt(account.country_id)),
      country_id: account && account.country_id,
      company_name: account && account.company_name,
    };

    return customer;
  }

  validateCountry() {
    const { country_id, country_name } = this.state.customer;

    if (country_id) {
      return !(!country_id || country_id === "0");
    } else if (country_name) {
      return !(
        !this.getCountryId(country_name) ||
        this.getCountryId(country_name) === "0"
      );
    }
  }

  getCountryName(countryId) {
    let countryName;
    this.props.countries.forEach((country) => {
      if (country.id === countryId) {
        countryName = country.description;
      }
    });
    return countryName;
  }

  getCountryId(countryName) {
    let countryId;
    this.props.countries.forEach((country) => {
      if (country.description === countryName) {
        countryId = country.id;
      }
    });
    return countryId;
  }

  handleCouponSubmit = (ev) => {
    ev.preventDefault();
    const { coupon } = this.state;
    const { subscription_type_id } = this.props.currentProduct;
    this.setState({ appliedCoupon: coupon });
    this.props.fetchCoupon(coupon, subscription_type_id, "1");
  };

  handleCouponChange = (ev) => {
    this.setState({ coupon: ev.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = getFormsValidityStatus(this.formStatus);

    if (isFormValid && this.validateCountry()) {
      this.setState({ error: "" });
      const { report } = this.props.match.params;
      const { customer, appliedCoupon } = this.state;

      this.props.history.push({
        pathname: `/product/${report}/payment`,
        state: { customer, appliedCoupon },
      });
      return;
    }
    this.setState({
      isFormSubmitted: true,
      error: "Form is invalid, please review your information.",
    });
    notifyError("Form is invalid, please review your information.");
  };

  renderComponentForField = (field) => {
    switch (field.data.name) {
      case "country_id":
        return (
          <Dropdown
            data={{
              ...field.data,
              list: this.props.countries
                ? this.props.countries
                : field.data.list,
              value:
                this.props.customer && this.props.customer.country_name
                  ? this.getCountryId(this.props.customer.country_name)
                  : this.state.customer && this.state.customer[field.data.name]
                  ? this.state.customer[field.data.name]
                  : field.data.value,
            }}
            onSelect={this.updateFormFieldValue}
          />
        );
      default:
        return (
          <TextField
            data={{
              ...field.data,
              value: this.props.customer
                ? this.props.customer[field.data.name]
                : this.state.customer && this.state.customer[field.data.name]
                ? this.state.customer[field.data.name]
                : field.data.value,
            }}
            customStyleClass="content__info-customer_input"
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
          />
        );
    }
  };

  render() {
    const {
      currentProduct,
      isLoadingProduct,
      couponDiscount,
      couponPrice,
      couponVAT,
      couponError,
    } = this.props;

    const {report} = this.props.match.params

    return (
      <PageLoading
        isLoading={isLoadingProduct}
        render={() => (
          <div className="product-page">
            <div className="header">
              <button
                onClick={() => this.props.history.goBack()}
                className="header__button"
              >
                <i className="fa fa-angle-left" aria-hidden="true" />
                <span>Go Back</span>
              </button>
            </div>
            <div className="title">
              <span>Order your TeamLytica {report === 'team-360' ? 'Team 360' : 'Benchmark'} Report </span>
            </div>
            <div className="content">
              {currentProduct ? (
                <div className="content__info">
                  <div className="content__info-product">
                    <div className="content__info-separator">
                      <span>Your chosen report</span>
                    </div>
                    {currentProduct && (
                      <>
                        <span className="content__info-product-name">
                          {currentProduct.name} -
                        </span>
                        <span>{currentProduct.description}</span>
                      </>
                    )}
                  </div>
                  <div className="content__info-customer">
                    <div className="content__info-separator">
                      <span>Payment Details</span>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                      {this.state.customerFields.map((field) => (
                        <div key={field.data.id}>
                          {this.renderComponentForField(field)}
                        </div>
                      ))}
                      <div className="error-message">
                        {this.state.errorMessage ? this.state.errorMessage : ""}
                      </div>

                      <div className="content__info-customer_footer">
                        <button type="submit" className="payment-button">
                          <span>Proceed to Payment</span>
                          <i className="fas fa-chevron-right" />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <span>Oops, we couldn&apos;t find your product</span>
              )}
              {currentProduct && (
                <div className="content__order">
                  <div className="content__order_header">
                    <span className="content__order_title">Order Value</span>
                    {currentProduct && (
                      <span className="content__order_value">
                        {getCurrency(currentProduct.currency)}
                        {couponPrice || couponPrice === 0
                          ? couponPrice
                          : currentProduct.price}
                      </span>
                    )}
                  </div>
                  <div className="content__order_form">
                    <div className="content__order_label">
                      <span>Discount Code (if applicable)</span>
                    </div>
                    <form
                      className="content__order_coupon"
                      onSubmit={this.handleCouponSubmit}
                    >
                      <input
                        className="content__order_coupon-input"
                        onChange={this.handleCouponChange}
                        type="text"
                      />
                      <button
                        className="content__order_coupon-submit"
                        type="submit"
                        onClick={this.handleCouponSubmit}
                      >
                        <span>Apply</span>
                        <i className="fas fa-chevron-right" />
                      </button>
                    </form>
                    {couponError && (
                      <div className="content__order_coupon-error">
                        <span>{couponError}</span>
                      </div>
                    )}
                  </div>
                  <div className="content__order_applies">
                    {couponDiscount && (
                      <div className="content__order_applies-value">
                        <span>Discount </span>
                        <span>
                          {currentProduct &&
                            getCurrency(currentProduct.currency)}
                          {couponDiscount}
                        </span>
                      </div>
                    )}
                    <div className="content__order_applies-value">
                      <span>VAT </span>
                      {currentProduct && (
                        <span>
                          {getCurrency(currentProduct.currency)}
                          {couponVAT || couponVAT === 0
                            ? couponVAT
                            : currentProduct.vat}
                        </span>
                      )}
                    </div>
                    <div className="content__order_applies-value">
                      <span>Total Amount </span>
                      {currentProduct && (
                        <span>
                          {getCurrency(currentProduct.currency)}
                          {couponPrice || couponPrice === 0
                            ? couponPrice
                            : currentProduct.price}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      />
    );
  }
}

Product.propTypes = {
  account: PropTypes.object,
  currentProduct: PropTypes.object,
  customer: PropTypes.object,
  couponDiscount: PropTypes.number,
  couponError: PropTypes.string,
  couponPrice: PropTypes.number,
  couponVAT: PropTypes.number,
  countries: PropTypes.array,
  isLoadingProduct: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  report: PropTypes.string,
  fetchCoupon: PropTypes.func,
  fetchProduct: PropTypes.func,
  fetchCountries: PropTypes.func,
  fetchCustomer: PropTypes.func,
  isFullReport: PropTypes.bool,
  isTeam360: PropTypes.bool,
};

const mapStateToProps = ({ general, payment, reports }) => {
  return {
    countries: general && general.countries,
    account: general && general.content,
    isLoadingProduct: payment && payment.isLoadingProduct,
    currentProduct: payment && payment.currentProduct,
    couponPrice: payment && payment.currentPrice && payment.currentPrice.price,
    couponError: payment && payment.couponError,
    couponDiscount:
      payment && payment.currentPrice && payment.currentPrice.discount,
    couponVAT: payment && payment.currentPrice && payment.currentPrice.vat,
    error: payment.error,
    isCustomerLoading: payment.isCustomerLoading,
    customer: payment.customer,
    customerError: payment.customerError,
    isTeam360:
      reports && reports.reportStatus && reports.reportStatus.is_team_360,
    isFullReport:
      reports && reports.reportStatus && reports.reportStatus.is_full_report,
  };
};

export default connect(mapStateToProps, {
  fetchCountries,
  fetchCoupon,
  fetchProduct,
  fetchCustomer,
})(Product);
