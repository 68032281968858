/*eslint react/no-deprecated: 0*/
import React from "react";

import PropTypes from "prop-types";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.data || {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state) {
      this.setState({
        ...nextProps.data
      });
    }
  }
  onChange = e => {
    this.props.onSelect(e.target.name, e.target.checked);
  };
  render() {
    const { checked, description, name, label, disabled } = this.state;
    return (
      <div>
        <label className="container">
          <div className="checkbox-label">{label}</div>
          <input
            type="checkbox"
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={this.onChange}
          />
          <span className="checkmark" />
          <div className="checkbox-description">{description}</div>
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  data: PropTypes.object,
  onSelect: PropTypes.func
};

export default Checkbox;
