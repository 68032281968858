import { actions } from "../actions/navigation.action";

export default function navigation(state = null, action) {
  switch (action.type) {
    case actions.SET_NAVIGATION_ASSETS:
      return {
        ...state,
        selection: action.payload
      };
    case actions.SET_BLURRED_CONTENT:
      return {
        ...state,
        blur: action.payload
      };
    default:
      return state;
  }
}
