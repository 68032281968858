/*eslint no-magic-numbers: 0*/
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Dialog as MuiDialog, DialogContent as MuiDialogContent } from "@mui/material";
import { withStyles } from '@mui/styles'

//components
import TextField from "../TextField";

import { resetReport } from "../../store/actions/reports.action";
import { getTeamsAction } from '../../store/actions/wizard.action'

// actions
import {
  deleteTeamMember,
  updateTeamMember,
  sendQuestionnaireToMember,
  sendQuestionnaireToTeam
} from "../../store/actions/team.action";

//utils
import * as data from "../../pages/Team/data.js";
import * as services from "../../store/services/validation.service";

// scss
import "./MemberDialog.scss";

const Dialog = withStyles(() => ({
  root: {
    marginTop: "20px",
    backgroundColor: "rgba(0, 0, 0, 0)"
  },

  paper: {
    margin: "0px 10px 0px 10px",
    maxWidth: "800px",
    backgroundColor: "rgba(0, 0, 0, 0)",
    boxShadow: "none"
  }
}))(MuiDialog);

const DialogContent = withStyles(() => ({
  root: {
    margin: "0px",
    padding: "0px",
    backgroundColor: "rgb(255, 255, 255)",
    border: "2px solid rgb(19,146,211)",
    borderRadius: "20px 0 20px 20px"
  }
}))(MuiDialogContent);

const HOCteamMemberDialog = (props) => {
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  return <TeamMemberDialog {...props} dispatch={dispatch} state={state} />
}

class TeamMemberDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [{ data: data.nameData }, { data: data.emailData }, {data: data.teamData}],
      isFormSubmitted: false,
      team: undefined,
      teams: []
    }
    this.formStatus = {};
  }
  handleTeamsTransform = (teams = []) => {
    return teams.map(team => ({
      ...team,
      description: team.name
    }))
  }
  componentDidMount = () => {
    const _companyId = localStorage.getItem("companyId");
    this.props.dispatch(getTeamsAction(_companyId))
   this.setState({ ...this.state, teams: this.handleTeamsTransform(this.props.state.teamWizard.teams) })
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  handleDialogClose = () => {
    const { error, onClose } = this.props;
    if (!error) {
      onClose();
    }
  };

  handleDeleteMember = () => {
    const { options, deleteTeamMember, isTeam360 } = this.props;
    deleteTeamMember(options[0][0],options[0][6], isTeam360).then(this.handleDialogClose);
  };

  handleEditMember = () => {
    const isFormValid = services.getFormsValidityStatus(this.formStatus);
    const { email, name } = this.state;
    const { options, updateTeamMember, isTeam360 } = this.props;

    if (isFormValid) {
      this.setState({ errorMessage: "" });
      const member = {
        id: options[0][0],
        email,
        name,
        team: options[0][6]
      };
      const team_id = options[0][6]
      updateTeamMember(member, team_id , isTeam360).then(this.handleDialogClose);
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Please review your information."
      });
    }
  };

  handleResetReport = () => {
    this.props
      .resetReport()
      .then(this.handleDialogClose)
      .then(this.props.history.push("/dashboard"));
  };

  handleSendQuestionnaireToMember = () => {
    const { options, sendQuestionnaireToMember, isTeam360 } = this.props;
    sendQuestionnaireToMember(options[0][6], options[0][0], isTeam360).then(
      this.handleDialogClose
    );
  };

  handleSendQuestionnaireToTeam = () => {
    const { sendQuestionnaireToTeam, isTeam360 } = this.props;
    const payload = {
      company_id: this.props.state.general.content.company_id,
      region_ids: (this.props.state.teamWizard.regions || []).map(item => item.id),
      area_ids: this.props.state.teamReview.areasByRegions,
      team_ids:[this.props.state.team.team_id]
    }
    sendQuestionnaireToTeam(payload, isTeam360).then(this.handleDialogClose);
  };

  handleOptionRendering = options => {
    switch (options[1]) {
      case "all":
        return this.renderSendAll();
      case "send":
        return this.renderSendQuestionnaire(options[0]);
      case "edit":
        return this.renderEditTeamMember(options[0]);
      case "delete":
        return this.renderDeleteTeamMember(options[0]);
      case "reset":
        return this.renderResetTeamStatus();
      default:
        return <div>OOPS</div>;
    }
  };

  handleDataTransform = (data = []) => {
    return data.map(item => ({
      id: item.value,
      description: item.name
    }))
  }

  

  renderComponentForField = (field, data) => {
    return (
      <Fragment>
        {field.data.name === "team" ?
          <div >
            <div className="dropdown-label">Team</div> 
            <select
              onChange={(event) => this.setState({...this.state, team: event.target.value})}
              className="select"
              defaultValue={this.props.options[0][3]}
            >
              {this.state.teams.map(option => {
                return (
                  <option value={option.id} key={option.id}>
                    {option.description}
                  </option>
                );
              })}
            </select>
          </div> 
          :
          <TextField
            data={{
              ...field.data,
              value: field.data.name === "name" ? data[1] : data[2]
            }}
            recordSelection={this.updateFormFieldValue}
            updateFormsStatus={this.updateFormsStatus}
            delay={500}
            isFormSubmitted={this.state.isFormSubmitted}
          />}

      </Fragment>
    );
  };

  renderResetTeamStatus = () => {
    return (
      <Fragment>
        <div className="member-dialog__content-title">
          <span>Start a new questionnaire?</span>
        </div>
        <div className="member-dialog__content-sub-title">
          <span>
            This will reset the team status and start a new questionnaire. Are
            you sure?
          </span>
        </div>
        <div className="member-dialog__content-actions">
          <button
            className="member-dialog__content-actions-submit"
            onClick={this.handleResetReport}
          >
            <span>Confirm</span>
            <i className="fa fa-paper-plane" aria-hidden="true" />
          </button>
        </div>
      </Fragment>
    );
  };

  renderSendAll = () => {
    return (
      <Fragment>
        <div className="member-dialog__content-title">
          <span>Send questionnaire to all team members?</span>
        </div>
        <div className="member-dialog__content-sub-title">
          <span>
            This will email all team members a link to complete the
            questionnaire
          </span>
        </div>
        <div className="member-dialog__content-actions">
          <button
            className="member-dialog__content-actions-submit"
            onClick={this.handleSendQuestionnaireToTeam}
          >
            <span>Confirm</span>
            <i className="fa fa-paper-plane" aria-hidden="true" />
          </button>
        </div>
      </Fragment>
    );
  };

  renderSendQuestionnaire = data => {
    return (
      <Fragment>
        <div className="member-dialog__content-title">
          <span>Send questionnaire to {data[1]}?</span>
        </div>
        <div className="member-dialog__content-sub-title">
          <span>
            This will email {data[1]} a link to complete the questionnaire
          </span>
        </div>
        <div className="member-dialog__content-actions">
          <button
            className="member-dialog__content-actions-submit"
            onClick={this.handleSendQuestionnaireToMember}
          >
            <span>Confirm</span>
            <i className="fa fa-paper-plane" aria-hidden="true" />
          </button>
        </div>
      </Fragment>
    );
  };

  renderEditTeamMember = data => {
    return (
      <Fragment>
        <div
          className="member-dialog__content-title"
          style={{ justifyContent: "flex-start" }}
        >
          <span>Edit a Team Member</span>
        </div>
        <div className="member-dialog__content-sub-title">
          <span>Update the details for member named &apos;{data[1]}&apos;</span>
        </div>

        <div className="member-dialog__content-form">
          {this.state.fields.map(field => (
            <div key={field.data.id}>
              {this.renderComponentForField(field, data)}
            </div>
          ))}
        </div>

        <div className="member-dialog__content-actions">
          <button
            className="member-dialog__content-actions-regular"
            onClick={this.handleEditMember}
          >
            <span>Save Changes</span>
            <i className="fa fa-check" aria-hidden="true" />
          </button>
        </div>
      </Fragment>
    );
  };

  renderDeleteTeamMember = data => {
    return (
      <Fragment>
        <div className="member-dialog__content-title">
          <span>Delete Team Member &apos;{data[1]}&apos;</span>
        </div>
        <div className="member-dialog__content-sub-title">
          <span>Are you sure you want to delete this team member?</span>
        </div>
        <div className="member-dialog__content-actions">
          <button
            className="member-dialog__content-actions-cancel"
            onClick={this.props.onClose}
          >
            <i className="fa fa-ban" aria-hidden="true" />
            <span>No</span>
          </button>
          <button
            className="member-dialog__content-actions-danger"
            onClick={this.handleDeleteMember}
          >
            <span>Yes, Delete</span>
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </div>
      </Fragment>
    );
  };

  renderCloseButton = () => {
    return (
      <div className="member-dialog__close-container">
        <button
          className="member-dialog__close-button"
          onClick={this.props.onClose}
        >
          <span>
            Cancel <i className="fa fa-times-circle" />
          </span>
        </button>
      </div>
    );
  };

  render() {
    const { open, onClose, options } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        BackdropProps={{
          className: "member-dialog__backdrop"
        }}
      >
        {this.renderCloseButton()}
        <DialogContent>
          <div className="member-dialog__container">
            <div className="member-dialog__content">
              {this.handleOptionRendering(options)}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const TYPES = {
  deleteTeamMember: PropTypes.func,
  error: PropTypes.string,
  history: PropTypes.object,
  isTeam360: PropTypes.bool,
  options: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  resetReport: PropTypes.func,
  sendQuestionnaireToMember: PropTypes.func,
  sendQuestionnaireToTeam: PropTypes.func,
  updateTeamMember: PropTypes.func,
}
HOCteamMemberDialog.propTypes = TYPES
TeamMemberDialog.propTypes = {
  ...TYPES,
  state: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  error: state.team.error
});

export default connect(
  mapStateToProps,
  {
    updateTeamMember,
    deleteTeamMember,
    resetReport,
    sendQuestionnaireToMember,
    sendQuestionnaireToTeam
  }
)(withRouter(HOCteamMemberDialog));

