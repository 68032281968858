/*eslint no-magic-numbers: 0*/
/*eslint react/no-deprecated: 0*/
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

//components
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import MemberDialog from "../../components/MemberDialog";
import PageLoading from "../../components/PageLoading";
import TableFooter from "../../components/TableFooter";
import TextField from "../../components/TextField";

// actions
import { setNavigationAssets } from "../../store/actions/navigation.action";
import {
  createTeamMember,
  fetchTeam,
  getTeamName,
  updateTeamName,
} from "../../store/actions/team.action";

import { renderDate } from "../../store/helpers/uiHelper";

//utils
import * as services from "../../store/services/validation.service";
import * as data from "./data";

// scss
import { withRouter } from "react-router-dom";
import PageContent from "../../components/PageContent";
import PageTitle from "../../components/PageTitle";
import "./Team.scss";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fields: [{ data: data.nameData }, { data: data.emailData }],
      searchField: { data: data.searchData },
      teamNameField: { data: data.teamData },
      isFormSubmitted: false,
      isAddMemberOpen: true,
      isTeamMemberDialogOpen: false,
      teamMemberDialogOptions: [],
      team: this.props.team,
      subtitle: data.SUBTITLE,
    };
    this.formStatus = {};
  }

  componentDidMount() {
    const navigation = {
      title: this.isTeam360() ? "STAKEHOLDERS" : "YOUR TEAM",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchTeam(this.isTeam360());
  }

  componentWillReceiveProps(nextProps) {
    const { allMembers } = nextProps;
    const handledData = this.handleData(allMembers);
    if (this.state.data !== handledData) {
      this.setState({ data: handledData, email: "", name: "" });
    }
  }

  canSeeLastReport() {
    const { reports } = this.props;
    const lastReport = reports && reports.slice(-1)[0];
    return lastReport && lastReport.canSeeReport;
  }

  isTeam360() {
    return this.props.history.location.pathname.includes("stakeholders");
  }

  handleData = (data) => {
    const handledData = [];

    data &&
      data.map((member) => {
        const memberData = [];
        memberData.push(
          member.id,
          member.name,
          member.email,
          member.status,
          member.added_on,
          member.status_id,
          member.team_id
        );
        return handledData.push(memberData);
      });

    return handledData;
  };

  updateFormsStatus = (fieldStatus, fieldName) => {
    this.formStatus[fieldName] = fieldStatus;
  };

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue });
  };

  handleAddMember = () => {
    const isFormValid = services.getFormsValidityStatus(this.formStatus);
    const { email, name } = this.state;

    if (isFormValid) {
      this.props.createTeamMember(
        this.props.teamId,
        email,
        name,
        this.isTeam360()
      );
      this.setState({ errorMessage: "" });
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Please review your information.",
      });
    }
  };

  handleChangeTeamName = () => {
    const isFormValid = services.getFormsValidityStatus(this.formStatus);
    const { team } = this.state;

    if (isFormValid) {
      this.props.updateTeamName(team, this.props.teamId);
      this.setState({ errorMessage: "" });
    } else {
      this.setState({
        isFormSubmitted: true,
        errorMessage: "Please review your information.",
      });
    }
  };

  handleHideMemberForm = () => {
    this.setState({
      isAddMemberOpen: !this.state.isAddMemberOpen,
    });
  };

  handleTeamMemberDialogClose = () => {
    this.setState({ isTeamMemberDialogOpen: false });
  };

  handleTeamMemberDialogOpen = (tableMeta, option) => {
    this.setState({
      isTeamMemberDialogOpen: true,
      teamMemberDialogOptions: [tableMeta, option],
    });
  };

  renderComponentForField = (field) => {
    return (
      <TextField
        data={{ ...field.data, value: this.state[field.data.name] }}
        recordSelection={this.updateFormFieldValue}
        updateFormsStatus={this.updateFormsStatus}
        delay={500}
        isFormSubmitted={this.state.isFormSubmitted}
      />
    );
  };

  disableButton = () => {
    const { data } = this.state;
    return data.find((array) => array[3] !== "Completed");
  };

  renderOptions = (tableMeta) => {
    if (tableMeta.rowData) {
      return (
        <div className="team-page__table-options">
          <button
            disabled={tableMeta.rowData[5] === 3}
            title="Send"
            className="team-page__table-options-send"
            onClick={() =>
              this.handleTeamMemberDialogOpen(tableMeta.rowData, "send")
            }
          >
            <i className="fa fa-paper-plane" />
          </button>
          {this.isTeam360() ? null : (
            <button
              disabled={this.disableButton()}
              title="Edit"
              className="team-page__table-options-edit"
              onClick={() =>
                this.handleTeamMemberDialogOpen(tableMeta.rowData, "edit")
              }
            >
              <i className="fa fa-edit" aria-hidden="true" />
            </button>
          )}

          <button
            disabled={this.disableButton()}
            title="Delete"
            className="team-page__table-options-delete"
            onClick={() =>
              this.handleTeamMemberDialogOpen(tableMeta.rowData, "delete")
            }
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </button>
        </div>
      );
    }
  };

  renderStatus = (status) => {
    return <div className={`team-page__table-status ${status}`}>{status}</div>;
  };

  render() {
    const {
      isAddMemberOpen,
      isTeamMemberDialogOpen,
      data,
      searchField,
      teamNameField,
      teamMemberDialogOptions,
    } = this.state;

    const { isLoadingPage, user } = this.props;

    const table = {
      columns: [
        {
          name: "id",
          options: {
            display: "excluded",
          },
        },
        {
          name: "Name",
          options: {
            sortDirection: "asc",
          },
        },
        "Email",
        {
          name: "Status",
          options: {
            customBodyRender: (value) => {
              return this.renderStatus(value);
            },
          },
        },
        {
          name: "Added on",
          options: {
            customBodyRender: (value) => {
              return renderDate(value, "long");
            },
          },
        },
        {
          name: "status_id",
          options: {
            display: "excluded",
          },
        },
        {
          name: "team_id",
          options: {
            display: "excluded",
          },
        },
        {
          name: "Options",
          options: {
            sort: false,
            customBodyRender: (value, tableMeta) => {
              return this.renderOptions(tableMeta);
            },
          },
        },
      ],
      options: {
        selectableRows: "none",
        elevation: 0,
        rowsPerPage: 1000,
        pagination: true,
        fixedHeader: true,
        filter: false,
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        searchText: this.state.searchValue,
        responsive: "scrollFullHeight",

        customFooter: (
          count,
          page,
          rowsPerPage,
          _changeRowsPerPage,
          changePage
        ) => {
          return (
            <TableFooter
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              changePage={changePage}
              user={user}
              isTeam360={this.isTeam360()}
              canSeeLastReport={this.canSeeLastReport()}
              handleTeamMemberDialogOpen={this.handleTeamMemberDialogOpen}
            />
          );
        },
      },
      theme: createTheme({
        typography: {
          fontFamily: "Montserrat",
          fontSize: 15,
          useNextVariants: true,
        },
        overrides: {
          MUIDataTableHeadCell: {
            root: {
              fontWeight: "bold",
              fontSize: 16,
              color: "#173b59",
              borderBottom: "2px solid #1392d3",
            },
          },
        },
      }),
    };

    const { columns, options, theme } = table;

    return (
      <PageLoading
        isLoading={isLoadingPage}
        render={() => (
          <>
            <PageTitle
              title={`${
                data?.length > 0 ? "Set up - Edit" : "Set up - Add Your"
              } ${this.isTeam360() ? " Stakeholders" : " Team Members"}`}
              subtitle={
                this.isTeam360()
                  ? this.state.subtitle.t360
                  : this.state.subtitle.noT360
              }
            >
              <MemberDialog
                open={isTeamMemberDialogOpen}
                onClose={this.handleTeamMemberDialogClose}
                options={teamMemberDialogOptions}
                isTeam360={this.isTeam360()}
              />
            </PageTitle>
            <PageContent>
              <div className="team-page__form">
                <div className="team-page__button-container">
                  <button
                    className="team-page__hide-button"
                    onClick={this.handleHideMemberForm}
                  >
                    <span>
                      {isAddMemberOpen
                        ? "Hide this form"
                        : this.isTeam360()
                        ? "Add New Stakeholders"
                        : "Add New Team Members"}
                      &nbsp;&nbsp;&nbsp;
                    </span>
                    <i className="fa fa-navicon" aria-hidden="true" />
                  </button>
                </div>
                {isAddMemberOpen ? (
                  <div className="team-page__container">
                    <div className="team-page__container-form">
                      <div className="team-page__container-form-content">
                        <TextField
                          data={{
                            ...teamNameField.data,
                            value: this.props.team,
                          }}
                          recordSelection={this.updateFormFieldValue}
                          updateFormsStatus={this.updateFormsStatus}
                          delay={500}
                          isFormSubmitted={this.state.isFormSubmitted}
                        />
                      </div>
                      <div className="team-page__container-form-submit">
                        <button
                          className="team-page__container-form-submit-button"
                          onClick={this.handleChangeTeamName}
                        >
                          <span>
                            {this.isTeam360() ? "Change name" : "Change name"}
                          </span>
                          <i className="fa fa-check" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="team-page__container-form-title">
                        <span>
                          {this.isTeam360()
                            ? "Add Stakeholder:"
                            : "Add Team Member:"}
                        </span>
                      </div>
                      <div className="team-page__container-form-content">
                        {this.state.fields.map((field) => (
                          <div key={field.data.id}>
                            {this.renderComponentForField(field)}
                          </div>
                        ))}
                      </div>
                      <div className="team-page__container-form-submit">
                        <button
                          className="team-page__container-form-submit-button"
                          onClick={this.handleAddMember}
                        >
                          <span>
                            {this.isTeam360()
                              ? "Add stakeholder"
                              : "Add member"}
                          </span>
                          <i className="fa fa-plus" aria-hidden="true" />
                        </button>
                        <button
                          className="team-page__container-form-submit-all"
                          onClick={() => {
                            this.handleTeamMemberDialogOpen(null, "all");
                            this.handleHideMemberForm();
                          }}
                        >
                          <span>Send questionnaire to all</span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="team-page__table">
                <div className="team-page__table-header">
                  <span className="team-page__table-header-title">
                    Your {this.isTeam360() ? "Stakeholders" : "Team Members"}
                  </span>
                  {data.length > 0 && (
                    <div className="team-page__table-header-search">
                      <TextField
                        data={{ ...searchField.data }}
                        recordSelection={this.updateFormFieldValue}
                        updateFormsStatus={this.updateFormsStatus}
                        delay={500}
                        isFormSubmitted={this.state.isFormSubmitted}
                      />
                    </div>
                  )}
                </div>

                {data.length > 0 ? (
                  <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                ) : (
                  <div className="team-page__table-no-content">
                    <span>
                      {this.isTeam360() ? "Stakeholders" : "Team Members"} will
                      appear here when you add them using the form above.
                    </span>
                  </div>
                )}
              </div>
            </PageContent>
          </>
        )}
      />
    );
  }
}

Team.propTypes = {
  allMembers: PropTypes.array,
  createTeamMember: PropTypes.func,
  updateTeamName: PropTypes.func,
  getTeamName: PropTypes.func,
  error: PropTypes.string,
  fetchTeam: PropTypes.func,
  isLoadingPage: PropTypes.bool,
  user: PropTypes.object,
  reports: PropTypes.array,
  teamId: PropTypes.number,
  reportStatus: PropTypes.object,
  setNavigationAssets: PropTypes.func,
  history: PropTypes.object,
  team: PropTypes.string,
};

const mapStateToProps = ({ team, reports, general }) => {
  return {
    allMembers: team.allMembers,
    teamId: team.team_id,
    team: team.team,
    isLoadingPage: team.isLoadingTeam,
    error: team.error,
    user: general.content,
    reports: reports.reports,
    reportStatus: reports.reportStatus,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setNavigationAssets,
    createTeamMember,
    fetchTeam,
    updateTeamName,
    getTeamName,
  })(Team)
);
