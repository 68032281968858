import { methods } from "../store/helpers/HttpHelper";
import { authHeader } from "../store/helpers/authHeader";
import { handleErrors } from "../store/helpers/errorHandlers";

const get = (url, options) => {
  const requestOptions = {
    method: methods.GET,
    headers: authHeader(),
    ...options
  };

  return fetch(url, {
    ...requestOptions,
  }).then(handleErrors)
    .then(response => response.json())
}

export const HttpClient = {
  get
}
