import React from "react";
import PropTypes from "prop-types";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "../CheckoutForm";

class StripePayment extends React.Component {
  render() {
    return (
      <StripeProvider
        apiKey={`${process.env.REACT_APP_TEAMMETRICS_STRIPE_PUBLIC_TEST_KEY}`}
      >
        <Elements>
          <CheckoutForm />
        </Elements>
      </StripeProvider>
    );
  }
}

StripePayment.propTypes = {
  product: PropTypes.object
};

export default StripePayment;
