import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./NoSubscription.scss";
import { connect } from "react-redux";
import { isAdminOrReseller } from "../../../../store/helpers/userHelper";

class NoSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = { reportType: props.reportType };
  }

  render() {
    const { reportType } = this.state;
    const { userRole } = this.props;
    return (
      <div className="no-subscription">
        <div className="no-subscription-container">
          <div className="no-subscription-title">
            <span>
              The {reportType} summary is not available to you on this package.
            </span>
          </div>
          {isAdminOrReseller(userRole) && (
            <div className="no-subscription-body">
              <p>
                The Team {reportType} summary is available for you to purchase.
                Simply upgrade to the full report and the full details of this
                sector, and all the other sectors will be available for you to
                review, share and print.
              </p>

              <Link
                to="/product/full-report"
                className="no-subscription-sub-btn"
              >
                <i className="fas fa-pie-chart" />
                <span>Upgrade to Full Report</span>
                <i className="fas fa-chevron-right" />
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ general }) => {
  return { userRole: general.content.role };
};

NoSubscription.propTypes = {
  reportType: PropTypes.string,
  userRole: PropTypes.string,
};

export default connect(mapStateToProps)(NoSubscription);
