import { actions } from "../actions/team.action";

const initialState = {
  isLoadingTeam: true,
  isLoadingTeamMember: false,
  isLoading: false,
  allMembers: [],
  member: {},
  team: "",
  team_id: undefined,
};

export default function team(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TEAM_REQUEST:
      return {
        ...state,
        isLoadingTeam: true,
        error: null,
      };
    case actions.FETCH_TEAM_SUCCESS:
      return {
        ...state,
        isLoadingTeam: false,
        allMembers: action.payload.members,
        team_id: action.payload.team_id,
        team: action.payload.team,
      };
    case actions.FETCH_TEAM_FAILURE:
      return {
        ...state,
        isLoadingTeam: false,
        error: action.error,
        allMembers: [],
        team_id: undefined,
      };
    case actions.CREATE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        isLoadingTeamMember: true,
        error: null,
      };
    case actions.CREATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingTeamMember: false,
      };
    case actions.CREATE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        isLoadingTeamMember: false,
        error: action.error,
      };
    case actions.DELETE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        isLoadingTeamMember: true,
        error: null,
      };
    case actions.DELETE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingTeamMember: false,
      };
    case actions.DELETE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        isLoadingTeamMember: false,
        error: action.error,
      };
    case actions.UPDATE_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        isLoadingTeamMember: true,
        error: null,
      };
    case actions.UPDATE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isLoadingTeamMember: false,
      };
    case actions.UPDATE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        isLoadingTeamMember: false,
        error: action.error,
      };
    case actions.UPDATE_TEAM_NAME_REQUEST:
      return {
        ...state,
        isLoadingTeam: true,
        error: null,
      };
    case actions.UPDATE_TEAM_NAME_SUCCESS:
      return {
        ...state,
        team: action.payload.team,
        isLoadingTeam: false,
      };
    case actions.UPDATE_TEAM_NAME_FAILURE:
      return {
        ...state,
        isLoadingTeam: false,
        error: action.error,
      };
    case actions.GET_TEAM_NAME_REQUEST:
      return {
        ...state,
        isLoadingTeam: true,
        error: null,
      };
    case actions.GET_TEAM_NAME_SUCCESS:
      return {
        ...state,
        team: action.payload.name,
        isLoadingTeam: false,
      };
    case actions.GET_TEAM_NAME_FAILURE:
      return {
        ...state,
        isLoadingTeam: false,
        error: action.error,
      };
    case actions.SEND_QUESTIONNAIRE_TO_MEMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actions.SEND_QUESTIONNAIRE_TO_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SEND_QUESTIONNAIRE_TO_MEMBER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case actions.SEND_QUESTIONNAIRE_TO_TEAM_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actions.SEND_QUESTIONNAIRE_TO_TEAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actions.SEND_QUESTIONNAIRE_TO_TEAM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
