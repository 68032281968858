/*eslint no-magic-numbers: 0*/
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import Dropdown from "../../../../components/Dropdown";
import PageLoading from "../../../../components/PageLoading";
import NoSubscription from "../../components/NoSubscription";
import QuestionDetail from "../../components/QuestionDetail";
import ReportsFooter from "../../components/ReportsFooter";
import ReportsTabbar from "../../components/ReportsTabbar";
import ScoreLabel from "../../components/ScoreLabel";

import {
  getSelectedReport,
  getVisibleReports,
} from "../../../../store/helpers/userHelper";

// actions
import {
  fetchReports,
  fetchReportStatus,
  fetchReportsTop10,
  updateSelectedReport,
} from "../../../../store/actions/reports.action";

import { setNavigationAssets } from "../../../../store/actions/navigation.action";

//utils
import * as data from "./data";

//scss
import PageContent from "../../../../components/PageContent";
import PageTitle from "../../../../components/PageTitle";
import { SECTIONS } from "../../../../constants/sections";
import "./Top10.scss";

class Top10 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportDropdown: { data: data.reportsData },
    };
    this.formStatus = {};
  }
  componentDidMount() {
    const navigation = {
      title: "METRIC REPORTS",
      subtitle: SECTIONS.TOP_SCORING,
      tab: "YOUR REPORTS",
    };
    this.props.setNavigationAssets(navigation);
    this.props.fetchReportsTop10();
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue }, () => {
      this.props.updateSelectedReport(fieldValue).then(() => {
        this.props.fetchReports();
        this.props.fetchReportStatus();
        this.props.fetchReportsTop10();
      });
    });
  };

  render() {
    const { isFullReport, isPageLoading, top10, isTeam360, reports } =
      this.props;

    const { reportDropdown } = this.state;

    let paragraphs = [];
    if (isTeam360) {
      paragraphs.push(
        "This section gives you an overview of the top scoring questions for your team across the whole report. These scores show what the team thinks it does really well together.",
        "Please note, these are team perception scores rather than actual performance. These questions can be used to praise the team as they are seen as an area of strength."
      );
    } else {
      paragraphs.push(
        "Your urgent actions shown below are the six lowest scoring questions within the survey. This will give the team a strong indication of where the team feels it’s weakest or where urgent action is required.",
        "Please note: Questions 43 and 44 ask about the style of your leader (empowering or directive). A low score in either of these does not mean this is an issue."
      );
    }

    return (
      <PageLoading
        isLoading={isPageLoading}
        render={() => (
          <Fragment>
            <PageTitle
              title="Your Top Scoring Questions"
              paragraphs={paragraphs}
              paragraphsClassNames={"parapgh-min-height"}
            >
              {reports && reports.length > 0 && (
                <div className="top10-page__header-title_select">
                  <Dropdown
                    data={{
                      ...reportDropdown.data,
                      list: getVisibleReports(reports) || [],
                      value: reports && getSelectedReport(reports),
                    }}
                    onSelect={this.updateFormFieldValue}
                  />
                </div>
              )}
            </PageTitle>
            <PageContent>
              <ReportsTabbar />
              {isFullReport ? (
                <div className="top10-page__content">
                  <ScoreLabel />
                  {top10 &&
                    top10.map((question) => (
                      <QuestionDetail
                        description={question.question_description}
                        average={question.average}
                        answers={question.answer_count}
                        answersTotal={question.total_answers}
                        number={question.question_number}
                        key={question.question_id}
                      />
                    ))}
                </div>
              ) : (
                <NoSubscription reportType="Top Scoring" />
              )}
              <ReportsFooter />
            </PageContent>
          </Fragment>
        )}
      />
    );
  }
}

Top10.propTypes = {
  fetchReports: PropTypes.func,
  fetchReportStatus: PropTypes.func,
  fetchReportsTop10: PropTypes.func,
  updateSelectedReport: PropTypes.func,
  setNavigationAssets: PropTypes.func,
  top10: PropTypes.array,
  isFullReport: PropTypes.bool,
  isLoadingTop10: PropTypes.bool,
  isLoadingReports: PropTypes.bool,
  isPageLoading: PropTypes.bool,
  isTeam360: PropTypes.bool,
  reports: PropTypes.array,
};

const mapStateToProps = ({ reports }) => {
  const isPageLoading = reports.isLoadingTop10 || reports.isLoadingReports;
  return {
    isFullReport:
      reports && reports.reportStatus && reports.reportStatus.is_full_report,
    top10: reports.top10,
    isLoadingTop10: reports.isLoadingTop10,
    isPageLoading,
    isTeam360:
      reports && reports.reportStatus && reports.reportStatus.is_team_360,
    reports: reports.reports,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  updateSelectedReport,
  fetchReports,
  fetchReportStatus,
  fetchReportsTop10,
})(Top10);
