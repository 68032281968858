import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";

import { compareTop10 } from "../../../../store/actions/benchmarking.action";
import { setNavigationAssets } from "../../../../store/actions/navigation.action";

import PageLoading from "../../../../components/PageLoading";
import ScoreLabel from "../../../../pages/Reports/components/ScoreLabel";
import CompareChart from "../../components/CompareChart";
import IndexScore from "../../components/IndexScore";
import ReportsFooter from "../../components/ReportsFooter";
import ReportsTabbar from "../../components/ReportsTabbar";

import PageContent from "../../../../components/PageContent";
import { INDICATORS } from "../../../../constants/metrics";
import { SECTIONS } from "../../../../constants/sections";
import NoSubscription from "../../components/NoSubscription";
import { REPORT_TEXTS } from "../../utils/contentTexts";
import "./Top10Compare.scss";

function Top10Compare(props) {
  const {
    setNavigationAssets,
    reports,
    comparedTop10,
    reportsToCompare,
    compareTop10,
    isLoadingPage,
    isFullReport,
    comparedQuestionAverages,
  } = props;

  React.useEffect(() => {
    const navigation = {
      title: "COMPARE AND BENCHMARK",
      subtitle: SECTIONS.TOP_SCORING,
      tab: "COMPARE AND BENCHMARK",
    };
    setNavigationAssets(navigation);
  }, []);

  React.useEffect(() => {
    if (reportsToCompare?.reportFrom && reportsToCompare?.reportTo) {
      compareTop10(
        reportsToCompare.reportFrom.id,
        reportsToCompare.reportTo.id
      );
    }
  }, [reportsToCompare]);

  const texts = REPORT_TEXTS.benchmark.top_10;

  return (
    <PageLoading
      isLoading={isLoadingPage}
      render={() => (
        <Fragment>
          <IndexScore
            title={texts.title}
            paragraphs={[texts.subtitle]}
            questionAveragesType={INDICATORS.TEAM_SYNERGY}
            isBenchmark={true}
            comparableReports={reports}
          />
          <PageContent>
            <ReportsTabbar compare={true} />
            <div className="mt-4">
              <ScoreLabel />
            </div>
            {isFullReport ? (
              <>
                {comparedTop10 &&
                  comparedTop10[0]?.map((question, i) => (
                    <div className="mb-4" key={question.question_id}>
                      <CompareChart
                        style={{ border: "none", padding: "20px 0" }}
                        data={{
                          title: `Q${question.question_order}. ${question.question_description}`,
                          initialData: {
                            score: question.average,
                            date: comparedQuestionAverages[0]?.date,
                          },
                          lastData: {
                            score: comparedTop10?.[1]?.[i]?.average,
                            date: comparedQuestionAverages[1]?.date,
                          },
                        }}
                      />
                    </div>
                  ))}
                <ReportsFooter showMemberStatus={false} />
              </>
            ) : (
              <NoSubscription reportType={SECTIONS.TOP_SCORING} />
            )}
          </PageContent>
        </Fragment>
      )}
    />
  );
}

Top10Compare.propTypes = {
  setNavigationAssets: PropTypes.func,
  reports: PropTypes.array,
  comparedTop10: PropTypes.array,
  reportsToCompare: PropTypes.object,
  compareTop10: PropTypes.func,
  isLoadingPage: PropTypes.bool,
  isFullReport: PropTypes.bool,
  comparedQuestionAverages: PropTypes.array,
};

const mapStateToProps = ({ reports, benchmarking }) => {
  const isLoadingPage = benchmarking.isLoadingComparedTop10;
  return {
    comparedQuestionAverages: benchmarking.comparedQuestionAverages,
    reports: reports.reports,
    isLoadingPage,
    comparedTop10: benchmarking.comparedTop10,
    reportsToCompare: benchmarking.reportsToCompare,
    isFullReport:
      reports && reports.reportStatus && reports.reportStatus.is_full_report,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  compareTop10,
})(Top10Compare);
