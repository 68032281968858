import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";

import useComparison from "../../utils/validateComparison";

import { compareUrgentActions } from "../../../../store/actions/benchmarking.action";
import { setNavigationAssets } from "../../../../store/actions/navigation.action";

import PageLoading from "../../../../components/PageLoading";
import ScoreLabel from "../../../../pages/Reports/components/ScoreLabel";
import CompareChart from "../../components/CompareChart";
import IndexScore from "../../components/IndexScore";
import QuestionDetail from "../../components/QuestionDetail";
import ReportsFooter from "../../components/ReportsFooter";
import ReportsTabbar from "../../components/ReportsTabbar";

import PageContent from "../../../../components/PageContent";
import { INDICATORS } from "../../../../constants/metrics";
import NoSubscription from "../../components/NoSubscription";
import { REPORT_TEXTS } from "../../utils/contentTexts";
import "./UrgentActionsCompare.scss";

function UrgentActionsCompare(props) {
  const {
    setNavigationAssets,
    reports,
    comparedUrgenctActions,
    reportsToCompare,
    compareUrgentActions,
    isLoadingPage,
    isFullReport,
    comparedQuestionAverages,
  } = props;

  useComparison();

  React.useEffect(() => {
    const navigation = {
      title: "COMPARE AND BENCHMARK",
      subtitle: "URGENT ACTIONS",
      tab: "COMPARE AND BENCHMARK",
    };
    setNavigationAssets(navigation);
  }, []);

  React.useEffect(() => {
    if (reportsToCompare?.reportFrom && reportsToCompare?.reportTo) {
      compareUrgentActions(
        reportsToCompare?.reportFrom?.id,
        reportsToCompare?.reportTo?.id
      );
    }
  }, [reportsToCompare]);

  const texts = REPORT_TEXTS.benchmark.urgent_actions;

  return (
    <PageLoading
      isLoading={isLoadingPage}
      render={() => (
        <Fragment>
          <IndexScore
            title={texts.title}
            paragraphs={texts.subtitle}
            questionAveragesType={INDICATORS.TEAM_SYNERGY}
            isBenchmark={true}
            comparableReports={reports}
          />
          <PageContent>
            <ReportsTabbar compare={true} />
            <div className="mt-4">
              <ScoreLabel />
            </div>
            {isFullReport ? (
              <>
                {" "}
                <h4 className="pt-4 mb-4">Lowest scoring questions</h4>
                {comparedUrgenctActions &&
                comparedUrgenctActions[1].lowest_scoring_questions.length > 0
                  ? comparedUrgenctActions[0].lowest_scoring_questions?.map(
                      (question, i) => {
                        return (
                          <div className="mb-4" key={question.question_id}>
                            <CompareChart
                              style={{ border: "none", padding: "20px 0" }}
                              data={{
                                title: `Q${question.question_order}. ${question.question_description}`,
                                initialData: {
                                  score: question.average,
                                  date: comparedQuestionAverages[0]?.date,
                                },
                                lastData: {
                                  score:
                                    comparedUrgenctActions[1]
                                      .lowest_scoring_questions[i].average,
                                  date: comparedQuestionAverages[1]?.date,
                                },
                              }}
                            />
                          </div>
                        );
                      }
                    )
                  : comparedUrgenctActions &&
                    comparedUrgenctActions[0].lowest_scoring_questions?.map(
                      (question) => (
                        <QuestionDetail
                          description={question.question_description}
                          average={question.average}
                          answers={question.answer_count}
                          answersTotal={question.total_answers}
                          number={question.question_number}
                          key={question.question_id}
                        />
                      )
                    )}
                <ReportsFooter showMemberStatus={true} />
              </>
            ) : (
              <NoSubscription reportType="Urgent Actions" />
            )}
          </PageContent>
        </Fragment>
      )}
    />
  );
}

UrgentActionsCompare.propTypes = {
  setNavigationAssets: PropTypes.func,
  reports: PropTypes.array,
  comparedUrgenctActions: PropTypes.array,
  reportsToCompare: PropTypes.object,
  compareUrgentActions: PropTypes.func,
  isLoadingPage: PropTypes.bool,
  isFullReport: PropTypes.bool,
  comparedQuestionAverages: PropTypes.array,
};

const mapStateToProps = ({ reports, benchmarking }) => {
  const isLoadingPage =
    benchmarking.isLoadingComparedCategoryAverages ||
    benchmarking.isLoadingComparedUrgenctActions;

  return {
    comparedQuestionAverages: benchmarking.comparedQuestionAverages,
    reports: reports.reports,
    isLoadingPage,
    comparedUrgenctActions: benchmarking.comparedUrgenctActions,
    reportsToCompare: benchmarking.reportsToCompare,
    isFullReport:
      reports && reports.reportStatus && reports.reportStatus.is_full_report,
  };
};

export default connect(mapStateToProps, {
  setNavigationAssets,
  compareUrgentActions,
})(UrgentActionsCompare);
