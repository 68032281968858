import React from "react";
import PropTypes from "prop-types";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

import Tabbar from "../Tabbar";

const Container = ({ children, options }) => {
  return (
    <div className="app">
      <Navbar user={true} />
      <div className="app__content">
        <Sidebar side="left" />
        <div className="layout__content">
          <div className="settings">
            <Tabbar options={options} />
            <div className="settings__content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  options: PropTypes.array
};

export default Container;
