import React, { Component } from "react";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div style={{ margin: "5em 10em 5em 10em" }}>
        <p>
          The TeamLytica Service (&quot;Platform&quot;) provided by TeamLytica
          Limited (&quot;we&quot;, &quot;us&quot; or &quot;TM&quot;) offers
          digital services related to team performance and cohesion reporting.
          Further details are set out in our Terms &amp; Conditions.
        </p>
        <p>&nbsp;</p>
        <p>
          TEAM METRICS Limited currently manages and maintains this Platform and
          we are committed to safeguarding and preserving the privacy of our
          visitors. This Privacy Policy explains what happens to any personal
          data that you provide to us, or that we collect from you whilst you
          visit our site. We do update this Policy from time to time so please
          do review this Policy regularly.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>What personal data do we use?</strong>
        </p>
        <p>We may collect and use the following information about you:</p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong>Information you give us:</strong> You may give us
            information about you by filling in forms on, or interacting with,
            the Platform, or by corresponding with us by phone, email or
            otherwise. The information you give us may include your name,
            address, email address and phone number, and information about your
            billing activities or circumstances (including your bank account
            details and Social Media profiles).
          </li>
          <li>
            <strong>Information we collect from you:</strong> When you use the
            Platform we may automatically collect technical information
            (including IP address, geolocation, device details and your login
            information) and information about each visit you make to the
            Platform (such as page response times and length of visit).
          </li>
          <li>
            <strong>Information provided voluntarily by you: </strong>For
            example, when you register for marketing information or pay for an
            Activation License that will include your Billing address, or
            through an online Survey to help improve our Platform.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <p>
            <strong>Use of Cookies</strong>
          </p>
          Cookies provide information regarding the device used by a visitor. We
          use cookies where appropriate to gather information about your
          experience in order to assist us in improving our Platform.
        </p>
        <p>&nbsp;</p>
        <p>
          We may gather information about your general internet use by using the
          cookie. Where used, these cookies are downloaded to your device and
          stored on the hard drive or memory of the device. Such information
          will not identify you personally - it is statistical data. This
          statistical data does not identify any personal details whatsoever.
        </p>
        <p>&nbsp;</p>
        <p>
          You can adjust the settings on your device to decline any cookies if
          you wish. This can easily be done by activating the reject cookies
          setting in your preferred web browser on your computer or phone. We
          use various third-party services including but not limited to:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>CloudFlare</li>
          <li>WooCommerce</li>
          <li>Facebook</li>
          <li>Google Analytics</li>
          <li>Stripe</li>
          <li>CampaignMonitor</li>
          <li>Hubspot</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          Our partners and advertisers may also use cookies, over which we have
          no control. Such cookies (if used) would be downloaded once you click
          on advertisements on our website.
        </p>
        <p>&nbsp;</p>
        <p>
          <p>
            <strong>Use of Your Information</strong>
          </p>
          We use the information that we collect from you to provide our
          services to you. In addition to this we may use the information for
          one or more of the following purposes:
          <br />{" "}
        </p>
        <ol>
          <li>
            To provide information to you that you request from us relating to
            our products or services.
          </li>
          <li>
            To provide information to you relating to other products that may be
            of interest to you. Such additional information will only be
            provided where you have consented to receive such information.
          </li>
          <li>
            To inform you of any changes to our website, services or goods and
            products.
          </li>
        </ol>
        <p>
          <br /> If you have previously subscribed to services from us we may
          provide to you details of similar services, or other goods and
          services, that you may be interested in. Where your consent has been
          provided in advance, we may allow selected third parties to use your
          data to enable them to provide you with information regarding
          unrelated goods and services which we believe may interest you. Where
          such consent has been provided it can be withdrawn by you at any time.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Where do we store your personal data?</strong>
        </p>
        <p>
          The personal information we collect from you is stored on secure
          cloud-based platforms located in the UK which are operated on our
          behalf by third parties (including CloudFlare, Amazon AWS and
          Microsoft Azure), but may also be accessed from outside the European
          Union when necessary for the provision of customer support, or as
          necessary to troubleshoot the Platform, or to comply with legal
          requirements.
        </p>
        <p>&nbsp;</p>
        <p>
          By agreeing to our Platform Terms and this Privacy Policy, you agree
          to this transfer, storing and processing. We will take all steps
          reasonably necessary to ensure that your personal information is
          treated securely and in accordance with this Privacy Policy.
        </p>
        <p>&nbsp;</p>
        <p>
          Transmitting information via the internet is never completely secure.
          Although we will do our best to protect your personal information, the
          security of your personal information transmitted to us, to or from
          any bank, or to or from any other third party, either by email or via
          the Platform, cannot be guaranteed. Any transmission is at your own
          risk. You acknowledge and agree that we shall not be responsible for
          any unauthorised use, distribution, damage or destruction of personal
          information, except to the extent we are required to accept such
          responsibility under the data protection laws.
        </p>
        <p>&nbsp;</p>
        <p>
          We do our upmost to ensure that all reasonable steps are taken to make
          sure that your data is treated stored securely within the UK. We
          currently follow the General Data Protection Regulation (GDPR) and UK
          Data Protection Rules and guidance to the storing of personal data.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Using a 3<sup>rd</sup> party online service connected through an API
          </strong>
        </p>
        <p>
          You may opt to connect the Platform to a 3<sup>rd</sup> party online
          service that is not within the UK or European Union for the purposes
          of enhancing your experience of the Platform. This will be through a
          secure RESTful API interface using OAuth2 protocols to the external
          site or service and permit data to be pushed or gather from the
          external site in order to enhance or improve your involvement with our
          services.
        </p>
        <p>&nbsp;</p>
        <p>
          These services use a tokenisation ID to connect your external account
          to our account on the Platform, or to process information such as:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong>Payment and Billing sites: </strong>For example, we utilise
            functionality provides through an API interface with partners such
            as Stripe and Xero
          </li>
          <li>
            <strong>Messaging Service: </strong> For example, we utilise
            functionality provides through an API interface with partners such
            as Twilio.
          </li>
          <li>
            <strong>Other services:</strong> For example, you may wish to
            connect to Social Media services such as Facebook, Twitter, Slack
            and other similar services in order to connect your Platform to
            their features.
          </li>
        </ul>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Standard Alerts and Notifications</strong>
        </p>
        <p>
          The Platform will send you certain messages and standard notifications
          sent to your email address during business workflows processes
          critical to the operation of the service. These are typically
          mandatory messages sent to inform you on matters pertaining to your
          account not limited to:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>Alerts associated with your Invoice reminders.</li>
          <li>
            Notification on actions needed to be undertaken on the App
            including, but not limited to, the review of overdue Invoices from
            your clients.
          </li>
          <li>Any Password reset requests.</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <p>
            <strong>Disclosing Your Information</strong>
          </p>
          We will not disclose your personal information to any other party
          other than in accordance with this Privacy Policy and in the
          circumstances detailed below. We do not store credit card details nor
          do we share customer details with any 3rd parties.
        </p>
        <ol>
          <li>
            In the event that we sell any or all of our business to the buyer.
          </li>
          <li>
            Where we are legally required by law to disclose your personal
            information.
          </li>
          <li>To further fraud protection and reduce the risk of fraud.</li>
        </ol>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <p>
            <strong>Third Party Links</strong>
          </p>
          On occasion we include links to third parties on this website. Where
          we provide a link it does not mean that we endorse or approve that
          site&apos;s policy towards visitor privacy. You should review their
          privacy policy before sending them any personal data.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Tracking tools</strong>
        </p>
        <p>
          The Platform uses tokens with an expiry time to collect information
          about your use of the Platform via Google analytics and Facebook Ad
          Pixels. We use this information to:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>Provide you with a good experience when you use our Platform.</li>
          <li>
            We gather insight for technical purposes essential to the operation
            of the Platform including and not limited to crash reports and
            critical issues with the servers.
          </li>
          <li>
            Time visits to the Platform and time spent using certain functions
            for marketing insight.
          </li>
          <li>
            We currently allow third parties to provide analytics services on
            our behalf (mentioned above). Such third parties track and examine
            the use of the Platform to prepare reports on its activities. This
            information may also be used to track or analyse data and is
            anonymous.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          We use well-known advertising platforms to tell you about the
          Platform. If you click through such an advert and agree to the
          Platform Terms, an automated process will confirm to the advertising
          platform that you have signed up to the Platform and how much you use
          the Platform.
        </p>
        <p>&nbsp;</p>
        <p>
          If you do not wish your use of the Platform to be tracked in this way,
          we recommend that you do not use the Platform. By continuing to use
          the Platform, you are agreeing to our use of these tracking
          mechanisms.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Protecting your account information</strong>
        </p>
        <p>
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorised access or disclose, we have put in place
          suitable physical, electronic and operational procedures to safeguard
          and secure the information we collect about you. In particular, we
          protect your information by deploying SSL and high standard encryption
          algorithms and offer 2-Factor Authentication (2FA) as an additional
          level of security for your account as optional functionality.
        </p>
        <p>&nbsp;</p>
        <p>
          To help us protect your information, you agree to comply with our
          security policies and procedures that we notify to you from time to
          time.
        </p>
        <p>&nbsp;</p>
        <p>
          You also agree to take all reasonable steps to prevent the
          unauthorised or fraudulent use of your User Login. If you suspect that
          anyone else knows your username or password, you must tell us at
          hello&#64;teamlytica.com. If necessary, we recommend you change your
          password every 90 days to prevent unauthorised access.
        </p>
        <p>&nbsp;</p>
        <p>
          <p>
            <strong>Access to Information</strong>
          </p>
          In accordance with the Data Protection Act 1998 you have the right to
          access any information that we hold relating to you. Please note that
          we reserve the right to charge a fee of &pound;10 to cover costs
          incurred by us in providing you with the information. We are
          registered with the Information Commissioner&apos;s Office in the UK
          registration number: A8484991.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Right to withdraw your data from our Platform</strong>
        </p>
        <p>
          At any time you have the legal right to request the withdrawal and
          removal of your personal information and data from the Platform. We
          will first process any outstanding final payments due to TeamLytica
          Limited and comply with your request. Please contact us at
          hello&#64;teamlytica.com with the formal request or in writing to
          TeamLytica Limited, Rowood House, Murdock Road, Bicester, Oxon, UK,
          OX26 4PP.
        </p>
        <p>&nbsp;</p>
        <p>
          This process will take 30 days and we will supply an electronically
          packaged file with your information to you according to your request.
          Your information will then be removed and deleted from our servers.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Retention period or criteria used to determine the retention period
          </strong>
        </p>
        <p>
          We reserve the right to retain your personal data on the Platform for
          a period of 365 days after you have deactivate your personal account,
          unless you formally request the removal of the data as per the clause
          above.
        </p>
        <p>&nbsp;</p>
        <p>
          <p>
            <strong>Contacting Us</strong>
          </p>
          Please do not hesitate to contact us regarding any matter relating to
          this Privacy Policy at hello&#64;teamlytica.com.
        </p>
      </div>
    );
  }
}

export default PrivacyPolicy;
