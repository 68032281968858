import PropTypes from "prop-types";
import React, { Component } from "react";

//components
import ProgressBar from "../../../../components/ProgressBar";

//utils
import { getValueColor } from "../../../../store/helpers/uiHelper";
import { isQuestionNumberColourful } from "../../../../utils/question-colours";

// scss
import clsx from "clsx";
import "./QuestionDetail.scss";

class QuestionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpandableOpen: false,
    };
  }

  handleExpandable = () => {
    const { isExpandableOpen } = this.state;

    this.setState({
      isExpandableOpen: !isExpandableOpen,
    });
  };

  render() {
    const { average, description, number } = this.props;

    const isColorful = isQuestionNumberColourful(number);

    return (
      <div className="question-detail">
        <div
          className="question-detail__container"
          onClick={this.handleExpandable}
        >
          <div className="question-detail__content">
            <span className="question-detail__content-question">
              Q{number}. {description}
            </span>
            <div
              className={clsx(
                "question-detail__content-value",
                isColorful && getValueColor(average)
              )}
            >
              <span>{average.toFixed(1)}</span>
            </div>
          </div>
          <div className="question-detail__progress">
            <ProgressBar value={average} total="10" color={isColorful} />
          </div>
        </div>
      </div>
    );
  }
}

QuestionDetail.propTypes = {
  answers: PropTypes.array,
  answersTotal: PropTypes.number,
  average: PropTypes.number,
  description: PropTypes.string,
  number: PropTypes.number,
};

export default QuestionDetail;
