import React, { Component } from "react";
import PropTypes from "prop-types";

import { getProgress } from "../../../../store/helpers/uiHelper";

// scss
import "./ProfileProgress.scss";

class ProfileProgress extends Component {
  render() {
    const { value, total } = this.props;
    return (
      <div className="profile-progress-bar">
        <label className="profile-progress-bar__label-left">
          <span>{`${getProgress(value, total)}%`}</span> Completed
        </label>
        <div className="profile-progress-bar__bar">
          <div style={{ width: `${getProgress(value, total)}%` }} />
        </div>
        <div className="profile-progress-bar__description">
          <span>
            Just a few more details to complete before downloading your free
            Guide to Project Management
          </span>
        </div>
      </div>
    );
  }
}

ProfileProgress.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number
};

export default ProfileProgress;
