import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./GlobalLoading.scss";

class GlobalLoading extends Component {
  render() {
    if (!this.props.isLoading) return null;

    return (
      <div className="global-loading">
        <i className="fa fa-spinner fa-spin fa-2x" />
        <label className="global-loading__message">Please wait...</label>
      </div>
    );
  }
}

const mapStateToProps = ({ globalLoading }) => ({
  isLoading: globalLoading.isLoading
});

GlobalLoading.propTypes = {
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(GlobalLoading);
