import React, { Component } from "react";
import PropTypes from "prop-types";

import "./PageLoading.scss";

/**
 * This component is based on rendering props:
 * https://cdb.reacttraining.com/use-a-render-prop-50de598f11ce
 *
 * Instead of using a HoC we will use a component that renders it's child
 * as the only condition is that it receives a loading props which shows
 * the actual loading of the page
 */
class PageLoading extends Component {
  render() {
    if (this.props.isLoading)
      return (
        <div className="page-loading">
          <i className="fa fa-spinner fa-spin fa-2x" />
          <label className="page-loading__message">
            {this.props.message || "Loading page..."}
          </label>
        </div>
      );

    return <div className="page-loaded">{this.props.render(this.state)}</div>;
  }
}

PageLoading.propTypes = {
  isLoading: PropTypes.bool,
  render: PropTypes.func,
  message: PropTypes.bool
};

export default PageLoading;
