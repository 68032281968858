/*eslint no-magic-numbers: 0*/
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// actions
import { fetchDashboardContent } from "../../store/actions/content.action";
import { fetchAccountProgress } from "../../store/actions/general.action";
import { setNavigationAssets } from "../../store/actions/navigation.action";
import {
  fetchQuestionAverages,
  fetchReportStatus,
  fetchReports,
  getSelectedReport,
  updateSelectedReport,
} from "../../store/actions/reports.action";

import Dropdown from "../../components/Dropdown";
import PageContent from "../../components/PageContent";
import PageLoading from "../../components/PageLoading";
import PageTitle from "../../components/PageTitle";
import ReportScore from "../../components/ReportScore";

//utils
import { INDICATORS } from "../../constants/metrics";
import * as data from "./data";

// scss
import "./Dashboard.scss";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportDropdown: { data: data.reportsData },
    };
    this.formStatus = {};
  }

  updateFormFieldValue = (fieldName, fieldValue) => {
    this.setState({ [fieldName]: fieldValue }, () => {
      this.props.updateSelectedReport(fieldValue).then(() => {
        this.props.fetchQuestionAverages();
        this.props.fetchAccountProgress();
        this.props.fetchReports();
        this.props.fetchReportStatus();
      });
    });
  };

  componentDidMount() {
    const navigation = { title: "DASHBOARD" };
    this.props.setNavigationAssets(navigation);
    this.props.fetchQuestionAverages();
    this.props.fetchAccountProgress();
    this.props.fetchReports();
    this.props.fetchReportStatus();
    this.props.fetchDashboardContent();
  }

  canRenderScore() {
    const { progress } = this.props;
    return progress.every((step) => {
      return step.state;
    });
  }

  render() {
    const {
      questionAverages,
      firstName,
      isLoadingDashboard,
      reports,
      report,
      companyName,
      teamName,
      paragraphs,
      canSeeReport,
    } = this.props;

    const benchmarkTile = {
      key: "bm",
      isDisabled: !canSeeReport || reports.length < 2,
    };

    const synergyScore = questionAverages?.[INDICATORS.TEAM_SYNERGY]
      ? {
          key: INDICATORS.TEAM_SYNERGY,
          value: questionAverages?.[INDICATORS.TEAM_SYNERGY],
        }
      : {
          key: INDICATORS.TEAM_SYNERGY,
          value: null,
        };

    const title = `${companyName} ${
      companyName && teamName && " - "
    } ${teamName} Dashboard`;

    return (
      <PageLoading
        isLoading={isLoadingDashboard}
        render={() => (
          <div className="dashboard-page">
            <Fragment>
              <div className="sub-header">
                <span>
                  Hi {firstName}, Welcome to your TeamLytica Dashboard!
                </span>
              </div>
              <PageTitle
                title={title}
                subtitle="TeamLytica is designed to help your team to improve
            performance and cohesion"
                paragraphs={paragraphs}
              >
                {reports && reports.length > 0 && (
                  <div className="dashboard-page__content-header_select">
                    <Dropdown
                      data={{
                        ...this.state.reportDropdown.data,
                        list: reports || [],
                        value: report?.id,
                      }}
                      onSelect={this.updateFormFieldValue}
                    />
                  </div>
                )}
              </PageTitle>
              <PageContent>
                <div className="flex-container">
                  <ReportScore
                    average={synergyScore.value}
                    averageType={synergyScore.key}
                    key={synergyScore.key}
                    isDisabled={!canSeeReport}
                  />
                  <ReportScore
                    average={null}
                    averageType={benchmarkTile.key}
                    key={benchmarkTile.key}
                    updateFormFieldValue={this.updateFormFieldValue}
                    disablePurchaseButton
                    isDisabled={benchmarkTile.isDisabled}
                  />
                </div>
              </PageContent>
            </Fragment>
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = ({ reports, general, content }) => {
  const isLoadingDashboard =
    general.isLoadingAccountProgress ||
    reports.isLoadingQuestionAverages ||
    reports.isLoadingReports ||
    content.dashboard.isLoading;

  return {
    questionAverages: reports.questionAverages,
    progress: general.progress,
    firstName: general.content.first_name,
    companyName: general.content.company_name && general.content.company_name,
    teamName: general.content.team_name && general.content.team_name,
    reports: reports.reports,
    report: reports.report,
    isFullReport: reports.reportStatus && reports.reportStatus.is_full_report,
    isTeam360: reports.reportStatus && reports.reportStatus.is_team_360,
    canSeeReport: reports.reportStatus && reports.reportStatus.can_see_report,
    isLoadingDashboard,
    paragraphs: content.dashboard.content.flatMap((item) =>
      item.value.split("\n")
    ),
  };
};

Dashboard.propTypes = {
  canSeeReport: PropTypes.bool,
  isFullReport: PropTypes.bool,
  isTeam360: PropTypes.bool,
  fetchAccountProgress: PropTypes.func,
  fetchQuestionAverages: PropTypes.func,
  fetchReports: PropTypes.func,
  fetchReportStatus: PropTypes.func,
  progress: PropTypes.array,
  questionAverages: PropTypes.object,
  setNavigationAssets: PropTypes.func,
  dashboard: PropTypes.object,
  currency: PropTypes.string,
  isLoadingDashboard: PropTypes.bool,
  firstName: PropTypes.string,
  reports: PropTypes.array,
  report: PropTypes.object,
  updateSelectedReport: PropTypes.func,
  getSelectedReport: PropTypes.func,
  companyName: PropTypes.string,
  teamName: PropTypes.string,
  fetchDashboardContent: PropTypes.func,
  dashboardContent: PropTypes.object,
  paragraphs: PropTypes.array,
};

export default withRouter(
  connect(mapStateToProps, {
    setNavigationAssets,
    fetchQuestionAverages,
    fetchAccountProgress,
    fetchReports,
    fetchReportStatus,
    updateSelectedReport,
    getSelectedReport,
    fetchDashboardContent,
  })(Dashboard)
);
