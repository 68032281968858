import { actions } from "../actions/subscription.action";

const initialState = {
  plan: {},
  key: {},
  isLoadingPlan: false,
  isLoadingKey: false,
  isLoadingTypes: false,
  error: null
};

export default function subscriptions(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_PLAN_STATUS_REQUEST:
      return {
        ...state,
        isLoadingPlan: true,
        error: null
      };
    case actions.FETCH_PLAN_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingPlan: false,
        plan: action.payload
      };
    case actions.FETCH_PLAN_STATUS_FAILURE:
      return {
        ...state,
        isLoadingPlan: false,
        error: action.payload
      };
    case actions.FETCH_PLAN_TYPES_REQUEST:
      return {
        ...state,
        isLoadingTypes: true,
        error: null
      };
    case actions.FETCH_PLAN_TYPES_SUCCESS:
      return {
        ...state,
        isLoadingTypes: false,
        types: action.payload
      };
    case actions.FETCH_PLAN_TYPES_FAILURE:
      return {
        ...state,
        isLoadingTypes: false,
        error: action.payload
      };
    case actions.FETCH_SUBSCRIPTION_KEY_REQUEST:
      return {
        ...state,
        isLoadingKey: true,
        error: null
      };
    case actions.FETCH_SUBSCRIPTION_KEY_SUCCESS:
      return {
        ...state,
        isLoadingKey: false,
        subscriptionKey: action.payload
      };
    case actions.FETCH_SUBSCRIPTION_KEY_FAILURE:
      return {
        ...state,
        isLoadingKey: false,
        error: action.payload
      };
    case actions.FETCH_SUBSCRIPTION_VALIDATION_REQUEST:
      return {
        ...state,
        error: null
      };
    case actions.FETCH_SUBSCRIPTION_VALIDATION_SUCCESS:
      return {
        ...state,
        isSubscriptionValid: true
      };
    case actions.FETCH_SUBSCRIPTION_VALIDATION_FAILURE:
      return {
        ...state,
        isSubscriptionValid: false,
        error: action.payload
      };
    case actions.UPDATE_ACTIVATION_KEY_REQUEST:
      return {
        ...state,
        isAccountUpdated: false,
        error: null
      };
    case actions.UPDATE_ACTIVATION_KEY_SUCCESS:
      return {
        ...state,
        isAccountUpdated: true
      };
    case actions.UPDATE_ACTIVATION_KEY_FAILURE:
      return {
        ...state,
        isAccountUpdated: false,
        error: action.payload
      };
    default:
      return state;
  }
}
